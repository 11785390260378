import fitnessTraining1 from "../assets/home4.png";
import fitnessTraining2 from "../assets/home3.png";
import fitnessTraining3 from "../assets/home2.png";

// Home Swiper Images
import homeSwiperImh1 from "../assets/haris-khan-review.jpg";
import homeSwiperImh2 from "../assets/aman-review.jpg";
import homeSwiperImh3 from "../assets/rajat-review.jpg";

// Trainers Data Images
import trainer1 from "../assets/tr2.png";
import trainer2 from "../assets/tr1.png";
// import trainer3 from "../assets/tr3.png";

// The Story Behind Our Gym imege
import story from "../assets/maninder-singh.png";

// allOurTrainersData Images
import ourTrainer1 from "../assets/home4.png";

import ourTrainers1 from "../assets/tr1.png";

import blogArticle1 from "../assets/blog2.png";
import blogArticle2 from "../assets/blog3.png";
import blogArticle3 from "../assets/blog4.png";
// import blogArticle4 from "../assets/blog5.png";
// import blogArticle5 from "../assets/blog6.png";
// import blogArticle6 from "../assets/blog7.png";

// Small Images
import thumbnail1 from "../assets/blog8.png";
// import thumbnail2 from "../assets/blog9.png";
// import thumbnail3 from "../assets/blog10.png";
// import thumbnail4 from "../assets/blog11.png";
// import thumbnail5 from "../assets/blog12.png";
// import thumbnail6 from "../assets/blog13.png";

//products images
import cofeeFlavorProtein1 from "../assets/white_coffee.jpg";
import cofeeFlavorProtein2 from "../assets/white_coffee_left side.jpg";
import cofeeFlavorProtein3 from "../assets/white_coffee_right side.jpg";

import wheyProtein1 from "../assets/protein.png";
// import wheyProtein2 from "../assets/tr1.png";
// import wheyProtein3 from "../assets/tr1.png";

import naturalSmooth1 from "../assets/natural_creamy.jpg";
import naturalSmooth2 from "../assets/jaggery_right.jpg";
import naturalSmooth3 from "../assets/jaggery_left.jpg";

import naturalCrunchy1 from "../assets/natural_crunchy.jpg";
import naturalCrunchy2 from "../assets/crunchy_right.jpg";
import naturalCrunchy3 from "../assets/crunchy_left.jpg";

import jaggeryCreamy1 from "../assets/jaggery_creamy.jpg";
import jaggeryCreamy2 from "../assets/jaggery_creamy_left.jpg";
import jaggeryCreamy3 from "../assets/jaggery_creamy_right.jpg";

import jaggeryCrunchy1 from "../assets/jaggery_crunchy.jpg";
import jaggeryCrunchy2 from "../assets/jaggery_crunchy_left.jpg";
import jaggeryCrunchy3 from "../assets/jaggery_crunchy_right.jpg";

import chocolateCreamy1 from "../assets/chocolate_creamy.jpg";
import chocolateCreamy2 from "../assets/chocolate_creamy_left.jpg";
import chocolateCreamy3 from "../assets/chocolate_creamy_right.jpg";

import chocolateCrunchy1 from "../assets/chocolate_crunchy.jpg";
import chocolateCrunchy2 from "../assets/chocolate_crunchy_left.jpg";
import chocolateCrunchy3 from "../assets/chocolate_crunchy_right.jpg";

import blueberryEAA from "../assets/blueberryEAA.png";
import creatine from "../assets/creatine.png";
import preworkout from "../assets/preworkout.png";
import fftshirt from "../assets/fftshirt.jpg";

import shaker1 from "../assets/shaker1.jpg";
import shaker2 from "../assets/shaker2.jpg";
import shaker3 from "../assets/shaker3.jpg";

export const headerLinksData = [
  {
    id: 1,
    title: "Home",
    to: "/",
  },
  {
    id: 2,
    title: "About",
    to: "/about",
  },
  // {
  //   id: 3,
  //   title: "Classes",
  //   to: "/classes",
  // },
  // {
  //   id: 4,
  //   title: "Trainers",
  //   to: "/trainers",
  // },
  {
    id: 5,
    title: "Blog",
    to: "/blog",
  },
  {
    id: 6,
    title: "Contact",
    to: "/contact",
  },
  {
    id: 7,
    title: "Products",
    to: "/products",
  },
];

export const fitnessTrainingData = [
  {
    id: 1,
    title: "Pilates Training",
    description: "containing Lorem Ipsum passage sand more recently with",
    image: fitnessTraining1,
    to: "/yogaClass",
  },

  {
    id: 2,
    title: "Aerobic Training",
    description: "containing Lorem Ipsum passage sand more recently with",
    image: fitnessTraining2,
    to: "/",
  },

  {
    id: 3,
    title: "CrossFit Workout",
    description: "containing Lorem Ipsum passage sand more recently with",
    image: fitnessTraining3,
    to: "/",
  },
];

export const homeSwiperData = [
  {
    id: 1,
    name: "Haris Khan",
    title: "",
    image: homeSwiperImh1,
    description: `The quality that Fitness Flame bring is top-notch, competing with the big players in the industry is tough but Fitness Flame has brought a big change in the end-consumer's mindset who were accustomed to prior's product.`,
  },

  {
    id: 2,
    name: "Aman",
    title: "",
    image: homeSwiperImh2,
    description: `Such an amazing product goes all out on my store. The general customer has been all positive regarding the taste and the quality. They have been one of my best dealings of all time. It's worth the money for their high-quality products.`,
  },

  {
    id: 3,
    name: "Rajat Kherni",
    title: "",
    image: homeSwiperImh3,
    description: `Amazing products and customer relations. Probably the best in the market in terms of quality and variety. Prices are reasonable for the quantity you get without compromising with the quality of the products. Worth the hype!`,
  }
];

export const trainersData = [
  {
    id: 1,
    name: "Vimal",
    title: "Certified Nutritionist, Personal Trainer & Online Fitness Coach",
    image: trainer1,
    to: "https://trainwithvimal.com/about-me/",
    info: "Hello, I'm Mr. Vimal, a certified nutritionist, personal trainer, and online fitness coach. I've had the privilege of transforming over 80+ individuals in achieving their fitness goals. My approach is centered on creating lasting lifestyle changes rather than short-term fixes.",
    shortInfo: `Guarantee:
    I offer a 100% money-back guarantee if you do not see any results within the first 21 days of starting the plan. No questions asked. Your satisfaction and progress are my top priorities.`
  },
  {
    id: 2,
    name: "Maninder Singh",
    title: "Calisthenics Athlete, Certified Nutritionist & Fitness Enthusiast",
    image: trainer2,
    to: "https://fitnessflamenutrition.com/",
    info: `Hello, I'm Maninder Singh Shergill, a Calisthenics Athelete, a certified Nutritionist and Fitness Enthusiast, I flip, I twist, and I push my limits. Conquer new calisthenics skills, push those personal records, and sprinkle in some fun workouts. Join us, where gains meet good vibes.`,
    shortInfo: `Let's crush it together! Ready to join the journey of strength and smiles?`
  }
];

export const gymAmenitiesData = [
  {
    id: 1,
    title: "Lockers",
    description: "Secure lockers that work by you choosing a four digit code.",
  },
  {
    id: 2,
    title: "Changing Rooms",
    description: `  Delicious, protein-packed gourmet shakes that come in chocolate,
              fruit, vanilla and more. Water and snacks too.`,
  },

  {
    id: 3,
    title: "Fuel Bar",
    description: `Mini towels for class and lovely big towels for showers after
              class. *Amenities may not be available at all studios`,
  },

  {
    id: 4,
    title: "Complimentary Towels",
    description: "Secure lockers that work by you choosing a four digit code.",
  },

  {
    id: 5,
    title: "Wifi & Restrooms",
    description: "Secure lockers that work by you choosing a four digit code.",
  },

  {
    id: 6,
    title: "Hot Showers & Hair Care",
    description: `what your body craves in the morning, you're not alone. The
              majority of people crank the handle all the way up.`,
  },
];

export const ourPlansData = [
  {
    id: 1,
    title: "Basic",
    offer: "₹2999 / month",
    description: "₹80 per day for a 90-day plan",
    icon: "fa-solid fa-heart",
    cardio: "Customized Meal Plan",
    strength: "Customized Workout Plan",
    times: "Protein upto 10% discount",
    muscle: "Weekly Check-ins"
  },
  // {
  //   id: 2,
  //   title: "Standard",
  //   offer: "₹3999 / month",
  //   description: "Most popular",
  //   icon: "fa-solid fa-star",
  //   cardio: "Cardio-Intensive Plan",
  //   strength: "Full Body Workout",
  //   times: "Weight Loss Exercise Plan",
  //   muscle: "Muscle Building Exercise Plan"
  // },

  {
    id: 3,
    title: "Advance",
    offer: "₹4999 / month",
    description: "₹100 per day for a 90-day plan",
    icon: "fa-solid fa-gem",
    cardio: "Basic Plan + Daily Check-ins",
    strength: "Eating Out + Traveling + Supplements Guidelines",
    times: "Stress Management Assessment and Guidelines",
    muscle: "Exclusive eBook: Lose 1kg Every Week"
  },
];

export const articlesNewsData = [
  {
    id: 1,
    about: "Fitness",
    date: "18 Feb, 2024",
    title:"THE ROLE OF NUTRITION IN FITNESS: FUELLING YOUR PERFORMANCE",
    shortInfo: "In the world of fitness, nutrition plays a vital role in helping individuals achieve their goals and maintain a healthy lifestyle. Nutrition, in simple terms...",
    link: "https://www.futurefit.co.uk/blog/role-of-nutrition-in-fitness/#:~:text=By%20consuming%20proteins%20before%20your,increased%20muscle%20mass%20over%20time"
  },

  {
    id: 2,
    about: "Health",
    date: "18 Feb, 2024",
    title: "How to Gain Muscle, No Matter Who You Are",
    shortInfo: "Muscle growth takes time, persistence, and a long-term commitment to the process. However, with proper training programs and adequate consumption of protein, it’s possible for most people......",
    link: "https://www.healthline.com/health/fitness/how-to-gain-muscle"
  },

  {
    id: 3,
    about: "Nutrition",
    date: "18 Feb, 2024",
    title: "Why It Matters: Nutrition",
    shortInfo: "Good nutrition is essential in keeping current and future generations of Americans healthy across the lifespan. Breastfeeding helps protect against childhood illnesses, .....",
    link: "https://www.cdc.gov/nutrition/about-nutrition/why-it-matters.html"
  },
];

export const gymStoryData = [
  {
    id: 1,
    welcome: "Welcome",
    title: "The Story Behind Fitness Flame",
    value: "We fuel the flame within you, yes, we are India’s most promising startup in the field of nutrition with the only ambition to provide you the best nutrition for your body at an affordable price. Our edible products flaunt zero adulteration and are of the most nutritional value without compromising with the taste."
  },

  {
    id: 2,
    title: "Story",
    image: story,
    value: "I believe optimum fitness can only be achieved by proper nutrition. Performing exercises without any well looked after diet might result in all the efforts going in vain. I started Fitness Flame Nutrition to sync in workout and nutrition together. Fitness is a complete journey of being what you have dream of and Fitness Flame is there for you to make it happen. - Maninder Singh Shergill"
  },
  {
    id: 3,
    title: "Our Mission",
    value: "Our mission: Elevate fitness. Empower lives. Through quality products and innovation, we inspire you to achieve your best self."
  },
  {
    id: 4,
    title: "Our Value",
    value: "Fitness fuels your vitality, fostering strength, resilience, and overall well-being. Embrace the journey to a healthier, happier you."
  },
];

export const valuesBoxes = [
  {
    id: 1,
    icon: "fa-regular fa-newspaper",
    title: "Certified trainer",
  },
  {
    id: 2,
    icon: "fa-solid fa-apple-whole",
    title: "Nutrition & diet",
  },

  {
    id: 3,
    icon: "fa-solid fa-person",
    title: "Years of experience",
  },
];

export const valuesNumbers = [
  {
    id: 1,
    number: "10+",
    description: "Year of Experience",
  },

  {
    id: 2,
    number: "500+",
    description: "Happy Clients",
  },

  {
    id: 3,
    number: "50+",
    description: "Expert Trainers",
  },

  {
    id: 4,
    number: "15k",
    description: "Instagram followers",
  },
];

export const allOurTrainersData = [
  {
    id: 1,
    title: "Pilates Training",
    image: ourTrainer1,
    to: "/yogaClass",
  },

  // {
  //   id: 2,
  //   title: "CrossFit",
  //   image: ourTrainer2,
  //   to: "/",
  // },

  // {
  //   id: 3,
  //   title: "Aerobic",
  //   image: ourTrainer3,
  //   to: "/",
  // },

  // {
  //   id: 4,
  //   title: "Yoga As Therapy",
  //   image: ourTrainer4,
  //   to: "/",
  // },

  // {
  //   id: 5,
  //   title: "Boxing",
  //   image: ourTrainer5,
  //   to: "/",
  // },

  // {
  //   id: 6,
  //   title: "Energy Dance",
  //   image: ourTrainer6,
  //   to: "/",
  // },
];

export const ourTrainers = [
  {
    id: 1,
    image: ourTrainers1,
    name: "Madison Fronting",
  },

  // {
  //   id: 2,
  //   image: ourTrainers2,
  //   name: "Joshua Frankilin",
  // },

  // {
  //   id: 3,
  //   image: ourTrainers3,
  //   name: "Miranda",
  // },
  // {
  //   id: 4,
  //   image: ourTrainers4,
  //   name: "Steve Smith",
  // },
  // {
  //   id: 5,
  //   image: ourTrainers5,
  //   name: "Kane Lucy",
  // },
  // {
  //   id: 6,
  //   image: ourTrainers6,
  //   name: "Jhon Willson",
  // },
];

export const products = [
  {
    id: 8,
    images: [cofeeFlavorProtein1, cofeeFlavorProtein2, cofeeFlavorProtein3],
    name: "Fitness Flame Whey Protein (Coffee) | 2kg",
    shortInfo: "Fitness Flame Organic Vegan Natural | 2kg | Coffee Beans ",
    description: `<p>Crave your hunger for protein with Fitness Flame Whey Protein!, boasting wholesome vitamins and minerals needed for your physical workouts and daily needs. High in vitamins E, B3, B6 and protein. Iron, magnesium, phosphorus, potassium and abundant minerals.
    <br/>Use it for mid-day snacks, pre-workout energy, post-workout nourishment, midnight cravings, or all day long! This contains all nine essential amino acids and can be used as a mid-afternoon snack or for post-workout recovery.
    <br/>Vitamins and minerals As part of a healthy diet and way of life, support a strong immune system. 50% of the recommended daily calcium intake and 30% of the recommended daily vitamin intake are needed to maintain strong bones. High Protein Each serving of whey protein contains lots of protein, which helps the body meet its requirements. Protein promotes longer-lasting satiety, heals tissue, and aids in the growth of muscles.</p>`,
    price: "3999",
    variant: {
      weight: "2 kg"
    },
    quantity: 0
  },
  {
    id: 7,
    images: [wheyProtein1],
    name: "Fitness Flame Whey Protein (Chocolate) | 2kg",
    shortInfo: "Fitness Flame Organic Vegan Natural | 2kg | Chocolate",
    description: `<p>Crave your hunger for protein with Fitness Flame Whey Protein!, boasting wholesome vitamins and minerals needed for your physical workouts and daily needs. High in vitamins E, B3, B6 and protein. Iron, magnesium, phosphorus, potassium and abundant minerals.
    <br/>Use it for mid-day snacks, pre-workout energy, post-workout nourishment, midnight cravings, or all day long! This contains all nine essential amino acids and can be used as a mid-afternoon snack or for post-workout recovery.
    <br/>Vitamins and minerals As part of a healthy diet and way of life, support a strong immune system. 50% of the recommended daily calcium intake and 30% of the recommended daily vitamin intake are needed to maintain strong bones. High Protein Each serving of whey protein contains lots of protein, which helps the body meet its requirements. Protein promotes longer-lasting satiety, heals tissue, and aids in the growth of muscles.</p>`,
    price: "3999",
    variant: {
      weight: "2 kg"
    },
    quantity: 0
  },
  {
    id: 11,
    images: [creatine],
    name: `Fitness Flame Creatine | 300gms`,
    shortInfo: "Fitness Flame Organic Vegan Natural | 300gms | Muscle Recovery | Creatine",
    description: ``,
    price: "999",
    variant: {
      weight: "300gms"
    },
    quantity: 0,
    outOfStock: true
  },
  {
    id: 10,
    images: [blueberryEAA],
    name: "Fitness Flame EAA (Blueberry) | 300gms",
    shortInfo: "Fitness Flame Organic Vegan Natural | 300gms | EAA | amino | essential | acid",
    description: ``,
    price: "1499",
    variant: {
      weight: "300gms"
    },
    quantity: 0,
    outOfStock: true
  },
  {
    id: 9,
    images: [preworkout],
    name: "Fitness Flame PreWorkOut | 300gms",
    shortInfo: "Fitness Flame Organic Vegan Natural | 300gms | pre-workout",
    description: ``,
    price: "999",
    variant: {
      weight: "300gms"
    },
    quantity: 0,
    outOfStock: true
  },
  {
    id: 1,
    images: [naturalSmooth1, naturalSmooth2, naturalSmooth3],
    name: "Fitness Flame Peanut Butter Natural (Smooth) | 1kg",
    shortInfo: "Fitness Flame Organic Vegan Natural Healthy Gluten Free Natural Peanut Butter | 1kg | High Protein Roasted Peanut Butter",
    description: `<p>Crave your hunger for protein with Fitness Flame Peanut Butter!, boasting wholesome vitamins and minerals needed for your physical workouts and daily needs. Every jar has that mouthwatering flavour of freshly roasted peanuts. High in vitamins E, B3, B6 and protein. Iron, magnesium, phosphorus, potassium and abundant minerals.
    <br/>Use it for mid-day snacks, pre-workout energy, post-workout nourishment, midnight cravings, or all day long! This contains all nine essential amino acids and can be used as a mid-afternoon snack or for post-workout recovery. Non-caffeinated
    <br/>Vitamins and minerals As part of a healthy diet and way of life, support a strong immune system. 50% of the recommended daily calcium intake and 30% of the recommended daily vitamin intake are needed to maintain strong bones. High Protein Each serving of peanut butter contains 6 grammes of protein, which helps the body meet its requirements. Protein promotes longer-lasting satiety, heals tissue, and aids in the growth of muscles.</p>`,
    price: "399",
    variant: {
      weight: "1 kg"
    },
    quantity: 0
  },
  {
    id: 2,
    images: [naturalCrunchy1, naturalCrunchy2, naturalCrunchy3],
    name: "Fitness Flame Peanut Butter Natural (Crunchy) | 1kg",
    shortInfo: "Fitness Flame Organic Vegan Natural | 1kg | Healthy Gluten Free Natural Peanut Butter | High Protein Roasted Peanut Butter",
    description: `<p>Crave your hunger for protein with Fitness Flame Peanut Butter!, boasting wholesome vitamins and minerals needed for your physical workouts and daily needs. Every jar has that mouthwatering flavour of freshly roasted peanuts. High in vitamins E, B3, B6 and protein. Iron, magnesium, phosphorus, potassium and abundant minerals.
    <br/>Use it for mid-day snacks, pre-workout energy, post-workout nourishment, midnight cravings, or all day long! This contains all nine essential amino acids and can be used as a mid-afternoon snack or for post-workout recovery. Non-caffeinated
    <br/>Vitamins and minerals As part of a healthy diet and way of life, support a strong immune system. 50% of the recommended daily calcium intake and 30% of the recommended daily vitamin intake are needed to maintain strong bones. High Protein Each serving of peanut butter contains 6 grammes of protein, which helps the body meet its requirements. Protein promotes longer-lasting satiety, heals tissue, and aids in the growth of muscles.</p>`,
    price: "399",
    variant: {
      weight: "1 kg"
    },
    quantity: 0
  },
  {
    id: 3,
    images: [jaggeryCreamy1, jaggeryCreamy2, jaggeryCreamy3],
    name: "Fitness Flame Peanut Butter Jaggery (Creamy) | 1kg",
    shortInfo: "Fitness Flame Organic Vegan Natural | 1kg | Healthy Gluten Free Jaggery Peanut Butter | High Protein Roasted Peanut Butter",
    description: `<p>Crave your hunger for protein with Fitness Flame Peanut Butter!, boasting wholesome vitamins and minerals needed for your physical workouts and daily needs. Every jar has that mouthwatering flavour of freshly roasted peanuts. High in vitamins E, B3, B6 and protein. Iron, magnesium, phosphorus, potassium and abundant minerals.
    <br/>Use it for mid-day snacks, pre-workout energy, post-workout nourishment, midnight cravings, or all day long! This contains all nine essential amino acids and can be used as a mid-afternoon snack or for post-workout recovery. Non-caffeinated
    <br/>Vitamins and minerals As part of a healthy diet and way of life, support a strong immune system. 50% of the recommended daily calcium intake and 30% of the recommended daily vitamin intake are needed to maintain strong bones. High Protein Each serving of peanut butter contains 6 grammes of protein, which helps the body meet its requirements. Protein promotes longer-lasting satiety, heals tissue, and aids in the growth of muscles.</p>`,
    price: "399",
    variant: {
      weight: "1 kg"
    },
    quantity: 0
  },
  {
    id: 4,
    images: [jaggeryCrunchy1, jaggeryCrunchy2, jaggeryCrunchy3],
    name: "Fitness Flame Peanut Butter Jaggery (Crunchy) | 1kg",
    shortInfo: "Fitness Flame Organic Vegan Natural | 1kg | Healthy Gluten Free Jaggery Peanut Butter | High Protein Roasted Peanut Butter",
    description: `<p>Crave your hunger for protein with Fitness Flame Peanut Butter!, boasting wholesome vitamins and minerals needed for your physical workouts and daily needs. Every jar has that mouthwatering flavour of freshly roasted peanuts. High in vitamins E, B3, B6 and protein. Iron, magnesium, phosphorus, potassium and abundant minerals.
    <br/>Use it for mid-day snacks, pre-workout energy, post-workout nourishment, midnight cravings, or all day long! This contains all nine essential amino acids and can be used as a mid-afternoon snack or for post-workout recovery. Non-caffeinated
    <br/>Vitamins and minerals As part of a healthy diet and way of life, support a strong immune system. 50% of the recommended daily calcium intake and 30% of the recommended daily vitamin intake are needed to maintain strong bones. High Protein Each serving of peanut butter contains 6 grammes of protein, which helps the body meet its requirements. Protein promotes longer-lasting satiety, heals tissue, and aids in the growth of muscles.</p>`,
    price: "399",
    variant: {
      weight: "1 kg"
    },
    quantity: 0
  },
  {
    id: 5,
    images: [chocolateCreamy1, chocolateCreamy2, chocolateCreamy3],
    name: "Fitness Flame Peanut Butter Chocolate (Creamy) | 1kg",
    shortInfo: "Fitness Flame Organic Vegan Natural | 1kg | Healthy Gluten Free Chocolate Peanut Butter | High Protein Roasted Peanut Butter",
    description: `<p>Crave your hunger for protein with Fitness Flame Peanut Butter!, boasting wholesome vitamins and minerals needed for your physical workouts and daily needs. Every jar has that mouthwatering flavour of freshly roasted peanuts. High in vitamins E, B3, B6 and protein. Iron, magnesium, phosphorus, potassium and abundant minerals.
    <br/>Use it for mid-day snacks, pre-workout energy, post-workout nourishment, midnight cravings, or all day long! This contains all nine essential amino acids and can be used as a mid-afternoon snack or for post-workout recovery. Non-caffeinated
    <br/>Vitamins and minerals As part of a healthy diet and way of life, support a strong immune system. 50% of the recommended daily calcium intake and 30% of the recommended daily vitamin intake are needed to maintain strong bones. High Protein Each serving of peanut butter contains 6 grammes of protein, which helps the body meet its requirements. Protein promotes longer-lasting satiety, heals tissue, and aids in the growth of muscles.</p>`,
    price: "399",
    variant: {
      weight: "1 kg"
    },
    quantity: 0
  },
  {
    id: 6,
    images: [chocolateCrunchy1, chocolateCrunchy2, chocolateCrunchy3],
    name: "Fitness Flame Peanut Butter Chocolate (Crunchy) | 1kg",
    shortInfo: "Fitness Flame Organic Vegan Natural | 1kg | Healthy Gluten Free Chocolate Peanut Butter | High Protein Roasted Peanut Butter",
    description: `<p>Crave your hunger for protein with Fitness Flame Peanut Butter!, boasting wholesome vitamins and minerals needed for your physical workouts and daily needs. Every jar has that mouthwatering flavour of freshly roasted peanuts. High in vitamins E, B3, B6 and protein. Iron, magnesium, phosphorus, potassium and abundant minerals.
    <br/>Use it for mid-day snacks, pre-workout energy, post-workout nourishment, midnight cravings, or all day long! This contains all nine essential amino acids and can be used as a mid-afternoon snack or for post-workout recovery. Non-caffeinated
    <br/>Vitamins and minerals As part of a healthy diet and way of life, support a strong immune system. 50% of the recommended daily calcium intake and 30% of the recommended daily vitamin intake are needed to maintain strong bones. High Protein Each serving of peanut butter contains 6 grammes of protein, which helps the body meet its requirements. Protein promotes longer-lasting satiety, heals tissue, and aids in the growth of muscles.</p>`,
    price: "399",
    variant: {
      weight: "1 kg"
    },
    quantity: 0
  },
  
  {
    id: 13,
    images: [shaker1, shaker2, shaker3],
    name: "Fitness Flame Shaker (Black) | Black | Shaker",
    shortInfo: "Fitness Flame Shaker | Black | Protein",
    description: ``,
    price: "199",
    variant: {},
    quantity: 0
  },
  {
    id: 12,
    images: [fftshirt],
    name: "Fitness Flame T-Shirt (Black) | Black | Tshirt",
    shortInfo: "Fitness Flame T-Shirt | Black | White | Tshirt",
    description: ``,
    price: "299",
    variant: {},
    quantity: 0
  }
];

export const blogArticlesNewsData = [
  {
    id: 1,
    title: "The 10 best exercises to do in your park",
    name: "Joe Holder",
    image: blogArticle1,
    thumbnail: thumbnail1,
    date: "Dec 29, 2023",
    paragraph1: `Embracing the outdoors for exercise not only improves physical health but also contributes to overall well-being. Parks provide a versatile and easily accessible space for a variety of workouts, catering to different fitness levels and preferences. So, lace up your sneakers, head to your local park, and discover the joy of staying fit amid nature's beauty.`,
    paragraph2: `In the hustle and bustle of modern life, finding time for exercise can be challenging. However, one solution is right outside your door - your local park. Parks offer a serene and open space for fitness enthusiasts to embrace the outdoors while staying active. Whether you're a seasoned fitness buff or a beginner looking to start a healthier lifestyle, here are the 10 best exercises you can do in your park:`,
    points: [`Take advantage of the park's peaceful atmosphere for yoga sessions.`,
    `Enhances flexibility, balance, and mental well-being.`,
    `Ideal for all fitness levels.`],
    yellowBoxData: `Join or organize group fitness classes in the park.
    Classes such as boot camps, tai chi, or Zumba offer a social and energetic workout experience.
    Encourages community engagement and motivation.`,
    subHeading: `The 3 steps for morning routines`,
    subParagraph: `Instead of telling you NOT to do something, here’s a simple morning flow you can replace the phone with. If the phone is not a temptation for you, this is also a practice I have found helpful for me even on days where the phone is not around. This can take as little as 10–15 minutes.`,
    steps: [`Step 1: Move. Wake up, move, and get into yourself. There'e no need to stress over exercise when you can just add it in at opportune times. ...`,
    `Step 2: Pause + Reflect. Take a moment to pause and/or reflect after movement. ...`,
    `Step 3: Plan. “Follow effective action with quiet reflection.`],
    about:`Founder of The Ocho System™, Plant Based Gang, and Exercise Snacks. Writer for @GQ. Consultant for various, primarily @nike @hyperice @dyson.`,
    link:"https://joeholder.medium.com/"
  },
  {
    id: 2,
    title: "How to Choose The Right Equipment For You",
    // name: "Benjamin Gray",
    image: blogArticle2,
    // thumbnail: thumbnail2,
    date: "Jan 6, 2024",
    paragraph1: `By harnessing gravity, body weight, external weight, or tension as a resistance force, these devices help you build strength. As with cardio equipment, styles and prices range widely, from expensive professional equipment most often found in gyms and health clubs to affordable, portable home models.`,
    paragraph2: `You can launch an effective exercise program using only what nature gave you: your body. But because regular activity remains an elusive goal for most people, a multibillion-dollar industry has blossomed around the promise of surefire success. Health club memberships and home exercise equipment are excellent exercise solutions for many people. Do keep these cautions in mind, though:`,
    points: [`Even the best equipment and most tricked-out gyms only produce results when used regularly.`,
    `Learn to use equipment properly to avoid injuries that could sideline you temporarily or permanently.`,
    `Exercise equipment comes in all sizes, shapes, and price ranges. It pays to check consumer ratings and follow our other tips for smart consumers before making your purchase.`],
    yellowBoxData: `When choosing fitness equipment, it's important to keep in mind your own individual needs and preferences. What works for someone else may not work for you so it's important to take the time to find the right piece of exercise equipment for your gym space. More importantly, remember that an effective exercise program should come first before you invest in equipment pieces.`,
    subHeading: `3 considerations when buying fitness equipment: `,
    subParagraph: `For many, working out involves lifting weights or using other gym equipment. While you can use weights and machines, those items aren't a requirement. You can work out using only your body weight.`,
    steps: [`Quality and durability should be at the top of your list, followed by ease of use and maintenance.`,
    `Safety should also be a priority when selecting any type of exercise equipment.`,
    `Make sure to factor in portability and storage needs before making a purchase.`],
    // about:`Founder of The Ocho System™, Plant Based Gang, and Exercise Snacks. Writer for @GQ. Consultant for various, primarily @nike @hyperice @dyson.`,
    // link:"https://joeholder.medium.com/"
  },
  {
    id: 3,
    title: "How to Maximum Time Spent at the Gym",
    // name: "David Ferguson",
    image: blogArticle3,
    // thumbnail: thumbnail3,
    date: "Feb 14, 2024",
    paragraph1: `The gym is a fantastic place to improve your physical health, boost your energy levels, and maintain overall wellbeing.`,
    paragraph2: `However, to truly maximise your gym experience, we have compiled eight valuable tips on how to make the most out of your visits to our gym, helping you achieve your fitness goals.`,
    points: [`Embrace your ‘happy hour’`,
    `Plan your workouts and set goals`,
    `Take part in our 12-week Fitter Faster Programme`,
    `Mix cardio and strength training`,
    `Workout with a friend`,
    `Incorporate workout classes`,
    `Achieve your goals even faster with a Personal Trainer`],
    yellowBoxData: `Thirty minutes of physical activity is – across the board for all adults – essential every day of the week. Meeting the 30-minute threshold at the gym will help a person maintain a healthy weight as well as reap health benefits such as lowering the risk of heart disease, osteoporosis, diabetes as well as hypertension.`,
    subHeading: `4 Strategies To Maximize Your Gym Time :`,
    subParagraph: `Whether you are brand new to the gym or a seasoned exerciser, your energy and time is valuable. With the busyness of day to day life, losing sight of keeping ourselves healthy and moving can easily go to the wayside. I’m here to give some of my best advice on how to maximize your gym time, making an efficient and effective workout an easy to accomplish priority! `,
    steps: [`PUT YOUR PHONE DOWN`,
    `MINIMIZE REST PERIODS OR UNNECESSARY BREAKS`,
    `INCORPORATE COMPOUND MOVEMENTS INTO YOUR GYM TIME`,
    `PLAN OR INVEST`],
  },

  // {
  //   id: 4,
  //   title: "Simple Condition for all Around Fitness.",
  //   name: "William Wilkins",
  //   image: blogArticle4,
  //   thumbnail: thumbnail4,
  // },

  // {
  //   id: 5,
  //   title: "How to Modify any Program to Improve Your Weakness",
  //   name: "Benjamin Gray",
  //   image: blogArticle5,
  //   thumbnail: thumbnail5,
  // },

  // {
  //   id: 6,
  //   title: `The Beginner’s Guide to Weight Lifting`,
  //   name: "Bernadette",
  //   image: blogArticle6,
  //   thumbnail: thumbnail6,
  // },
];

export const contactInfoData = [
  {
    id: 1,
    title: "Phone",
    icon: "fa-solid fa-phone",
    cta: "+91 911-517-0676",
    value: "Have questions or concerns? Reach out"
  },
  {
    id: 2,
    title: "Email",
    icon: "fa-solid fa-envelope",
    cta: "support@fitnessflamenutrition.com",
    value: "Contact us at email address for assistance"
  },

  {
    id: 3,
    title: "Location",
    icon: "fa-solid fa-location-dot",
    cta: "Zirakpur, 140603, Punjab.",
    value: "Fitness Flame Nutrition Store, VIP Road Bhabhat"
  },
];

export const couponsData = [
  {
    id: 1,
    name: "MANINDER10",
    discount: 10,
    type: "percentage"
  },
  {
    id: 2,
    name: "HOLISPECIAL",
    discount: 20,
    type: "percentage"
  }
];