import { products } from "../../data/data";
import styles from "./products.module.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import ReactGA from "react-ga4";

const Products = () => {
  const [productsList, setProducts] = useState([]);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  ReactGA.send({ hitType: "pageview", page: "/products", title: "Products" });

  useEffect(() => {
    setProducts(products);
    if(JSON.parse(localStorage.getItem("cart")) && JSON.parse(localStorage.getItem("cart")).length > 0){
      
      let cartItems = JSON.parse(localStorage.getItem("cart"));
      cartItems.forEach(element => {
        if(products && products.length > 0) {
          let i = products.findIndex(i => i.id === element.id);
          products[i].quantity = element.quantity;
        }
      });
    }
  }, []);

  const addToCart = (item) => {
    const index = productsList.findIndex(i => i.id === item.id);
    productsList[index].quantity++;
    setProducts(productsList);
   
    const cartValue = JSON.parse(localStorage.getItem("cart")) ? JSON.parse(localStorage.getItem("cart")) : [];
    const existingItemIndex = cartValue.findIndex(i=> i.id === item.id);
    if(existingItemIndex > -1) {
      cartValue[existingItemIndex].quantity = cartValue[existingItemIndex].quantity + 1;
      let i = products.findIndex(i => i.id === item.id);
      products[i].quantity = cartValue[existingItemIndex].quantity;
    } else {
      cartValue.push({id: item.id, quantity: productsList[index].quantity});
    }
    localStorage.setItem("cart", JSON.stringify(cartValue));
    setCount(count + 1);
    window.location.reload();
  }

  const filterProducts = (e) => {
    e.preventDefault();
    let searchParam = document.getElementById("search").value;
    ReactGA.event({
      category: "prod_search_" + searchParam,
      action: "prod_search_" + searchParam,
      label: "prod_search_" + searchParam, // optional
      value: 1, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
    if(searchParam.length > 0) {
      let h = [];
      products.forEach(i => {
        // console.log((i.name).toLowerCase().includes(searchParam.toLowerCase()))
        if((i.name).toLowerCase().includes(searchParam.toLowerCase()) || (i.shortInfo).toLowerCase().includes(searchParam.toLowerCase())) {
          h.push(i);
        }
      });
      setProducts(h);
    } else {
      setProducts(products);
    }
  }

  // const increaseCount = (item) => {
  //   const cartData = JSON.parse(localStorage.getItem("cart")) ? JSON.parse(localStorage.getItem("cart")) : [];
  //   const i = cartData.findIndex(i=> i.id === item.id);
   
  //   cartData[i].quantity = cartData[i].quantity + 1;
  //   localStorage.setItem("cart", JSON.stringify(cartData));

  //   let index = products.findIndex(i => i.id === item.id);
  //   products[index].quantity = cartData[i].quantity;
  //   setCount(count + 1);

  // }

  // const decreaseCount = (item) => {
  //   const cartData = JSON.parse(localStorage.getItem("cart")) ? JSON.parse(localStorage.getItem("cart")) : [];
  //   const i = cartData.findIndex(i=> i.id === item.id);
  //   cartData[i].quantity = cartData[i].quantity - 1;
  //   let index = products.findIndex(i => i.id === item.id);
  //   products[index].quantity = cartData[i].quantity;
  //   if(cartData[i].quantity > 0) {
  //   } else if(cartData[i].quantity <= 0) {
  //     cartData.splice(i, 1);
  //   }
  //   localStorage.setItem("cart", JSON.stringify(cartData));
    
  //   setCount(count + 1);
  // }

  const canBrowserShareData = (data) => {
    if (!navigator.share || !navigator.canShare) {
      return false;
    }
  
    return navigator.canShare(data);
  }

  const goToCart = () => {
    navigate("/cart");
  }

  const share = (item) => {
    ReactGA.event({
      category: "prod_share" + item.name,
      action: "prod_share" + item.name,
      label: "prod_share" + item.name, // optional
      value: 1, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
    const sharedDataSample = {
      title: item.name,
      text: item.shortInfo,
      url: "https://www.fitnessflamenutrition.com/#/products",
    };
    
    if (canBrowserShareData(sharedDataSample)) {
      // Enable the share button in the UI.
      navigator.share(sharedDataSample);
    } else {
      // We can't share on this browser/operating system.
      ReactGA.event({
        category: "prod_share_err" + item.name,
        action: "prod_share_err" + item.name,
        label: "prod_share_err" + item.name, // optional
        value: 1, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
    }
  }

  return (
    <div className={`${styles.products} container sections-padding mt-48`}>
      
      <div className={styles.products_head}>
        <form>
          <div className={styles.search_bar}>
            <input className={styles.search_input} type="text" placeholder="Search..." id="search"/>
            <button className={styles.search_button} onClick= {filterProducts}>Search</button>
          </div>
        </form>
        <p className="paragraph">Our Products</p>
      </div>

      <div className={styles.card_view}>
        {productsList.map((element) => {
          return (
            <div className={styles.card}>
              <div className={styles.img_share}>
                <img className={( element.id === 11 ||  element.id === 9 || element.id === 10 || element.id === 12)? `${styles.card_image_creatine}` : `${styles.card_image}`} src={element.images[0]} alt="" />
                <button className={styles.share_icon} onClick={() => share(element)}><i class="fa fa-share-alt"></i></button>
              </div>
              <h1 className={ (element.id === 11 ||  element.id === 9 || element.id === 10 || element.id === 12)? `${styles.card_title_mt72}` : `${styles.card_title}`}>{element.name} </h1>
              <h4 className={styles.card_info}>{element.shortInfo}</h4>
              <h3 className={styles.card_price}>₹ {element.price}</h3>
              {!element.outOfStock && element.quantity === 0 && <button className={styles.buy_btn} onClick={() => addToCart(element) }>Add To Cart</button>}
              {element.outOfStock && <button className={`${styles.added_disabled}`}>Out Of Stock</button> }
              {/* { element.quantity > 0 && 
               <div className={styles.addsubtract_btn}>
                <button className={styles.buy_btn} onClick={(e) => increaseCount(element)}>+</button>
                  <span>{element.quantity}</span>
                  <button className={styles.buy_btn_decrement} onClick={(e) => decreaseCount(element)}>-</button>
                </div>
              } */}
              {!element.outOfStock && element.quantity > 0 && <button className={styles.cart_btn} onClick={() => goToCart(element) }>Go To Cart</button>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Products;
